import { useEffect } from "react";
import { makeStyles, Typography, Grid, Button, Theme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Steps, useAppState } from "../../../state";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import demoEmployees from "../../../data/demoEmployees.json";

interface ChatSelectionScreenProps {
  name: string;
  roomName: string;
  phone: string;
  setStep: (step: Steps) => void;
}

export default function ChatLobbyScreen({
  name,
  roomName,
  phone,
  setStep
}: ChatSelectionScreenProps) {
  const {
    getToken,
    isFetching,
    coverURL,
    isAgent,
    hBoxRequest,
    hRequestId,
    suggestedDepartment
  } = useAppState();

  const useStyles = makeStyles((theme: Theme) => ({
    customerBgContainer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage: `url(${coverURL})`,
      backgroundSize: "cover",
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "20vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top"
      }
    },
    gutterBottom: {
      marginBottom: "1em"
    },
    marginTop: {
      marginTop: "1em"
    },
    deviceButton: {
      width: "100%",
      border: "2px solid #aaa",
      margin: "1em 0"
    },
    localPreviewContainer: {
      paddingRight: "2em",
      marginBottom: "2em",
      [theme.breakpoints.down("sm")]: {
        padding: "0 2.5em"
      }
    },
    joinButtons: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "100%",
        "& button": {
          margin: "0.5em 0"
        }
      }
    },
    mobileButtonBar: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        margin: "1.5em 0 1em"
      }
    },
    mobileButton: {
      padding: "0.8em 0",
      margin: 0
    },
    toolTipContainer: {
      display: "flex",
      alignItems: "center",
      "& div": {
        display: "flex",
        alignItems: "center"
      },
      "& svg": {
        marginLeft: "0.3em"
      }
    }
  }));

  let phone_number = null;
  let demoNumber = "";
  let employeeName = "";
  let employeeImage = "";
  let customerServiceURL = "";
  function getEmployee() {
    console.log("demoEmployees", demoEmployees);
    let employees = demoEmployees.demoEmployees;
    for (let i = 0; i < employees.length; i++) {
      let employee = employees[i];
      if (
        suggestedDepartment
          .toLowerCase()
          .includes(employee.department.toLowerCase()) &&
        employee.on_duty
      ) {
        console.log("Employee found", employee);
        employeeName = employee.name;
        employeeImage = employee.image;
        phone_number = employee.phone_number;
        break;
      }
    }
  }

  getEmployee();

  let hostServerURL = window.location.hostname;
  hostServerURL === "localhost"
    ? (hostServerURL = "http://localhost:8081")
    : (hostServerURL = "https://" + window.location.hostname);
  let params = new URLSearchParams(document.location.search);
  if (phone_number != null) {
    demoNumber = phone_number;
  } else {
    demoNumber = params.get("demo") as string;
  }
  let ClientIDUrl = params.get("ClientID");
  let LocationIDUrl = params.get("LocationID");
  let employeeMessage = "";

  async function generateToken(
    phone_number: string,
    name: string,
    help_text: string
  ) {
    if (help_text == null || help_text == "") {
      help_text = "No Details";
    }
    const response = await fetch(`${hostServerURL}/generate-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        phone_number: phone_number,
        name: name,
        help_text: help_text
      })
    });
    const result = await response.json();
    let token = result.token;
    employeeMessage = `Chat Help Request: ${hRequestId} ${hostServerURL}/room/${roomName}?agent=ca&chat&ClientID=${ClientIDUrl}&LocationID=${LocationIDUrl}&token=${token}`;
    customerServiceURL = `${hostServerURL}/sms-chatme?message=${encodeURIComponent(
      employeeMessage
    )}&surl=${encodeURIComponent(hostServerURL)}`;

    if (demoNumber != null && demoNumber != "") {
      console.log("Routing to: ", demoNumber);
      customerServiceURL = `${hostServerURL}/sms-chatme?message=${encodeURIComponent(
        employeeMessage
      )}&surl=${encodeURIComponent(hostServerURL)}&demoNumber=${demoNumber}`;
    }
  }

  const isAnAgent = isAgent();
  useEffect(() => {
    setStep(Steps.chatLobby);
    const chatNotify = async () => {
      try {
        await generateToken(phone, name, hBoxRequest);
        console.log("serviceURL", customerServiceURL);
        const response = await fetch(customerServiceURL, { method: "POST" });
        if (response.ok) {
          const data = await response.json();
          console.log("Response:", data);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.error(error);
      }
    };
    isAnAgent ? console.log("Hello Agent") : chatNotify();
  }, [customerServiceURL, roomName, isAnAgent, setStep]);

  const classes = useStyles();
  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const cancelLobby = () => {
    setStep(Steps.selectContactChannel);
  };

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      chatConnect(token);
      videoConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <br />
        <br />
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Joining ChatRoom
          </Typography>
        </div>
        <br />
        <br />
      </Grid>
    );
  }

  return (
    <>
      {!isAgent() && (
        <>
          <div className={"centrado"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                background: "#F29849"
              }}
            >
              <div className={classes.customerBgContainer}></div>
            </div>
            <br />
            <Typography variant="h6"> Your help request is sent! </Typography>
            <br />
            <img
              src={
                employeeImage
                  ? employeeImage
                  : "../../../images/Generic-Profile-image.png"
              }
              style={{ width: "100px", height: "100px" }}
            ></img>
            {/* <Typography> An associate will be there soon!</Typography> */}
            <Typography>
              {suggestedDepartment && employeeName
                ? `${employeeName} from the ${suggestedDepartment} department will be with you soon!`
                : `An associate will be with you soon!`}{" "}
            </Typography>
            <Typography> Please join the chat room.</Typography>
            <br />
            <div className={classes.joinButtons}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleJoin}
                disabled={disableButtons}
              >
                Join Chat Room
              </Button>
              <Button variant="outlined" color="primary" onClick={cancelLobby}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}

      {isAgent() && (
        <>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={handleJoin}
            disabled={disableButtons}
          >
            Join Chat Room
          </Button>
        </>
      )}
    </>
  );
}
